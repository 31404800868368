const BARTENDER_WEB_PRINT_SERVICE_URL = "http://localhost:8632/BarTenderWebPrintService";

/**
 * Send a request to the print service for a print license for the selected printer. Fires callback after completion.
 */
export function initiateCreateClientPrintLicense(clientPrinterName, callback) {
    createPrintLicense(clientPrinterName, function(printLicenseInfo) {
        if (printLicenseInfo.Success) {
            callback(printLicenseInfo.PrintLicense);
            return;
        }

        console.log("Failed to create print license for printer " + clientPrinterName);
        showPrintingError();
    });
}

/**
 * Create a printer license for the specified printer. Fires callback after completion.
 */
export function createPrintLicense(clientPrinterName, callback)
{
    var url = BARTENDER_WEB_PRINT_SERVICE_URL + '/CreatePrintLicensePrintQueue?printerName=' + encodeURIComponent(clientPrinterName) +
        '&rand=' + Math.floor((Math.random()*10000)+1);

    $.ajax({
        type: 'GET',
        url: url,
        cache: false,
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        success: function(data){
            callback(data);
        },
        error: function(jqXHR, textStatus) {
            console.log('Error while creating print license: ' + textStatus);
            showPrintingError();
        }
    });
}

/**
 * Send a request to the printing API to generate the print code for the specified printer. Fires callback after completion.
 */
export function generateClientPrintCode(clientPrinterName, printLicense, callback) {
    $.ajax({
        url: Routing.generate('ajax_generate_printcode'),
        type: 'POST',
        data: JSON.stringify({
            clientPrintLicense: printLicense,
            clientPrinterName: clientPrinterName
        }),
        contentType: 'application/json',
        dataType: 'json',
        success: function (data) {
            if (!data.hasOwnProperty('success') || !data.success || !data.hasOwnProperty('printCode')) {
                console.log('Error while requesting print code: ' + (data.hasOwnProperty('error')) ? data.error : data);
                showPrintingError();
                return;
            }

            callback(data.printCode);
        },
        error: function () {
            showPrintingError();
        }
    });
}

/**
 * Sends the specified print code to the selected client printer. Fires callback after completion.
 */
export function initiateClientPrint(clientPrinterName, printCode, callback)
{
    clientPrint(clientPrinterName, printCode, function(result) {
        if (result.Success){
            callback(result.JobName);
            return;
        }

        console.log("An error occurred while sending the print code to the client printer: " + result.ErrorMessage);
        showPrintingError();
    });
}

/**
 * Prints the encoded print code to the specified printer. Fires callback after completion.
 */
export function clientPrint(clientPrinterName, printCode, callback)
{
    var url = BARTENDER_WEB_PRINT_SERVICE_URL + '/PrintToPrintQueue?printerName=' + encodeURIComponent(clientPrinterName) +
        '&printJobNamePrefix=' + encodeURIComponent("Web Label Print SDK Example") +
        '&rand=' + Math.floor((Math.random()*10000)+1);

    $.ajax({
        type: 'POST',
        url: url,
        contentType: 'text/plain',
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        },
        data: printCode,
        success: function(data){
            callback(data);
        },
        error: function(jqXHR, textStatus) {
            console.log('Error while sending printcode to client printer service: ' + textStatus);
            showPrintingError();
        }
    });
}

export function showPrintingError() {
    var printStatus = $("#print_status");
    printStatus.html(Translator.trans("badge.printing_failed", {}, "messages"));
    printStatus.css("color", "red");
}

export function showPrintingSuccess() {
    var printStatus = $("#print_status");
    printStatus.html(Translator.trans("badge.printing", {}, "messages"));
    printStatus.css("color", "green");
}

export function showBadgeSentSuccessfully() {
    var printStatus = $("#print_status");
    printStatus.html(Translator.trans("badge.send_success", {}, "messages"));
    printStatus.css("color", "green");
}

export function getPrinterName(preregistrationId, callback)
{
    var url = Routing.generate('admin_ajax_preregistration_printer_share');
    $.ajax({
        url: url,
        data: {
            preregistration: preregistrationId,
        },
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Failed getting printer name", e);
            showPrintingError();
        },
    });
}

/**
 * check what printing method is selected for a specific Preregistration
 */
export function getPrintingMethodForPreregistration(preregistrationId, successCallback)
{
    $.ajax({
        url: Routing.generate('admin_ajax_preregistration_print_method'),
        data: {
            preregistration: preregistrationId,
        },
        method: 'POST',
        success: successCallback,
        error: function (e) {
            console.log("Failed getting printing method", e);
            showPrintingError();
        },
    });
}

/**
 * check what printing method is selected for a specific Registration
 */
export function getPrintingMethodForRegistration(registrationId, successCallback)
{
    $.ajax({
        url: Routing.generate('admin_ajax_registration_print_method'),
        data: {
            registration: registrationId,
        },
        method: 'POST',
        success: successCallback,
        error: function (e) {
            console.log("Failed getting printing method", e);
            showPrintingError();
        },
    });
}

export function validatePrintAbilityPreregistration(preregistrationId)
{
    let response;
    $.ajax({
        async: false,
        url: Routing.generate('admin_ajax_preregistration_validate_print_ability'),
        data: {
            preregistration: preregistrationId,
        },
        method: 'POST',
        success: function (responseData) {
            response = responseData;
        },
        error: function (e) {
            console.log("Failed validating print ability", e);
            showPrintingError();
        },
    });

    return response;
}

export function validatePrintAbilityRegistration(registrationId)
{
    let response;
    $.ajax({
        async: false,
        url: Routing.generate('admin_ajax_registration_validate_print_ability'),
        data: {
            registration: registrationId,
        },
        method: 'POST',
        success: function (responseData) {
            response = responseData;
        },
        error: function (e) {
            console.log("Failed validating print ability", e);
            showPrintingError();
        },
    });

    return response;
}

/**
 * check what printing method is selected for a specific Preregistatration
 */
export function getPrintingMethodForEntryPoint(entryPointId, callback)
{
    var url = Routing.generate('ajax_entry_point_print_method');
    $.ajax({
        url: url,
        data: {
            entryPoint: entryPointId,
        },
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Failed getting printing method", e);
            showPrintingError();
        },
    });
}

export function downloadQRCodeForPreregistration(preregistrationId, callback)
{
    var url = Routing.generate('admin_ajax_download_qr_code');
    $.ajax({
        url: url,
        data: {
            preregistration: preregistrationId,
        },
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Something went wrong downloading the QR code for this preregistration", e);
            showPrintingError();
        },
    });
}

/**
 * make a badge from a preregistration
 */
export function sendPrintRequestToPrintNodeForPreregistration(preregistrationId, callback)
{
    var url = Routing.generate('admin_ajax_print_preregistration_with_print_node');
    $.ajax({
        url: url,
        data: {
            preregistration: preregistrationId,
        },
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Something went wrong printing on printNode: ", e);
            showPrintingError();
        },
    });
}

export function sendPrintRequestToPrintNodeForRegistration(registrationId, callback)
{
    var url = Routing.generate('admin_ajax_print_registration_with_print_node');
    $.ajax({
        url: url,
        data: {
            registration: registrationId,
        },
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Something went wrong printing on printNode: ", e);
            showPrintingError();
        },
    });
}
