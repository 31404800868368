$(function () {
    if (0 === $('#registration-form').length) {
        return;
    }

    let $hostEl =  $('.select-host');
    let $visitReason = $('.select-visit-reason');

    updateHostData($visitReason, $hostEl);
    $visitReason.on('change', function() {
        if(!$hostEl.length) {
            return;
        }
        updateHostData($visitReason, $hostEl);
    });
});

function setDefaultHost($hostEL, $visitReason){
    let $defaultHost = JSON.parse($visitReason.attr('data-default-hosts'));
    $hostEL.val($defaultHost[$visitReason.val()]);
    $hostEL.trigger('change');
}

function updateHostData($visitReason, $hostEl)
{
    var visitReasonId = null;
    if($visitReason.length > 0){
        visitReasonId = $('select.select-visit-reason').val();
    }
    var targetUrl = Routing.generate('ajax_allowed_host_visit_reasons', {'visit-reason' : visitReasonId});
    var data = getRequest(targetUrl);
    var hostData = data['hosts'];
    var selectedHost = data['selected-host'];

    fillSelectOptions($hostEl, hostData);
    if(selectedHost != null){
        $hostEl.val(selectedHost);
        $hostEl.trigger('change');
    }else{
        setDefaultHost($hostEl, $visitReason);
    }
}

function getRequest(targetUrl)
{
    var response = [];
    $.ajax({
        url: targetUrl,
        type: 'GET',
        cache: false,
        dataType: 'json',
        //data: postData,
        async: false
    }).done(function (data) {
        response = data;
    });

    return response;
}

function fillSelectOptions(element, data)
{
    var selected = element.select2("val");

    element.empty();
    element.select2('val', '');

    $.each(data, function(index, value) {
        element.append($("<option></option>")
            .attr("value", value["id"]).text(value["text"]));
    });

    element.val(selected);
}
