/**
 * Lightbox
 *
 * This component requires jQuery and Magnific popup to function.
 * You can require it on the page using <div data-component="lightbox"></div>
 */

require('magnific-popup');

$( function() {

	$( '.popup-content' ).magnificPopup(
		{
			type: 'inline',
			preloader: false,
			focus: '#username',
			modal: true
		}
	);

	$( document ).on( 'click', '.popup-modal-dismiss', function( e ) {
		e.preventDefault();
		$.magnificPopup.close();
	} );

} );
