/**
 * Main
 *
 * The main file, page logic which is needed on all pages will be included here.
 * Please note for specific components like a slider create a separated components/<component_name>.js
 * The components will be loaded through the component loader and are not needed on every page!
 */

import {
    initiateCreateClientPrintLicense,
    generateClientPrintCode,
    initiateClientPrint,
    getPrintingMethodForEntryPoint,
    showPrintingError
} from "../shared/print";

require('jquery.mmenu');
require('magnific-popup');

$( function() {
    disablePinchZoom();
    disableTrustguardClick();

    $( '#nav' ).mmenu(
        {
            scrollBugFix: true,
            offCanvas: {
                position: 'right'
            }
        }, {
            clone: true
        }
    );

    $( 'body' )
        .on( 'click', 'a[href="#top"]', function( event ) {
            event.preventDefault();

            $( 'html, body' ).animate( { scrollTop: 0 } );
        } )
    ;

    var $checkbox = $( 'input[type="checkbox"]');
    $checkbox.wrap( '<div class="checkbox"></div>' );
    $( '<span></span>' ).insertAfter( '.checkbox input[type="checkbox"]' );

    $checkbox.change(function( ) {
        if($(this).prop('checked')){
            $(this).parents('label').addClass('is-active');
        } else {
            $(this).parents('label').removeClass('is-active');
        }
    });

} );

// Info (?) on fields
$("[data-info-description]").each(function() {

    var description = $(this).data('info-description');
    if (description.length === 0) {
        return;
    }

    $(this).parent().append($("<span></span>")
        .append(this) // Move our input to the new parent
        .append('<a class="question popup_content" data-component="lightbox" href="#info-popup">?</a>')
    );

    $( this ).next().magnificPopup(
        {
            type: 'inline',
            preloader: false,
            focus: '#username',
            modal: true
        }
    );
});

$("[data-info-description] + .popup_content").click(function() {
    var $input = $(this).prev(); // can be an input on the registration form, or a link on the instructions step!
    if (!$input.data("info-description")) { // For phone number fields, other other fields that wrap one or more container divs around the input field.
        $input = $input.find("input");
    }
    var description = $input.data('info-description');
    var title = $input.data('info-title');

    var $info_content = $("#info-popup .help__content");
    $info_content.empty();

    if (title) {
        $info_content.append('<h2>' + title + '</h2>');
    }

    if (description) {
        $info_content.append('<p>' + description + '</p>');
    }
});

$("#checkbox-modal").modal('show');

$("form input, form textarea").on('change valid invalid', function() {

    if (!this.willValidate) {
        return;
    }

    this.setCustomValidity('');

    if (this.validity.valid) {
        return;
    }

    var errors = [];
    for (var key in this.validity) {

        if (key === 'valid' || key === 'customError') {
            continue;
        }

        if (this.validity[key]) {
            errors.push(Translator.trans("validation." + key, {}, "messages"));
        }
    }

    if (errors.length > 0) {
        this.setCustomValidity(errors[0]);
    }
});

$(".has-error").each(function() {
    var $helpBlock = $(this).find('.help-block');
    $helpBlock.addClass('is-error');

    $(this).after($helpBlock);
});

function disableTrustguardClick(){
    $(".tgfloat img").css('pointer-events', 'none');
}

function disablePinchZoom(){
    document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function (e) {
        e.preventDefault();

        document.body.style.zoom = 0.99;
    });
    document.addEventListener("gestureend", function (e) {
        e.preventDefault();
        document.body.style.zoom = 1;
    });
}

export function initBadgePrintingOnCheckIn() {
    let entryPointId = $('.entry_point_id').val();
    getPrintingMethodForEntryPoint(entryPointId, function (printMethod) {
        if ('bartender' === printMethod) {
            var clientPrinterName = $('.client_printer_name').val();

            initiateCreateClientPrintLicense(clientPrinterName, function (printLicense) {
                generateClientPrintCode(clientPrinterName, printLicense, function (printCode) {
                    initiateClientPrint(clientPrinterName, printCode, function (printJobId) {
                        console.log("Successfully sent print job with id: " + printJobId);

                        showAfterPrintingInstructionsOrContinue(5000);
                    });
                });
            });
        } else if ('print_node' === printMethod) {
            sendPrintRequestToPrintNode(function (printJobId) {
                console.log("Successfully sent PrintNode print job with id: " + printJobId)

                showAfterPrintingInstructionsOrContinue(10000);
            })
        } else {
            console.error('unrecognised printing method: ' + printMethod);
        }
    });
}

function showAfterPrintingInstructionsOrContinue(time) {
    let afterPrintingPopup = $(".after_printing_instructions_popup");

    var timeOut = 3000; //default
    if (afterPrintingPopup.length) {
        afterPrintingPopup.removeClass("hidden");
        timeOut = time;
    }

    setTimeout(function () {
        window.location.href = Routing.generate('check_in_cancel');
    }, timeOut);
}

function sendPrintRequestToPrintNode(callback)
{
    //what registration needs to be printed is determined on the server side
    var url = Routing.generate('ajax_print_registration_with_print_node');
    $.ajax({
        url: url,
        data: {},
        method: 'POST',
        success: callback,
        error: function (e) {
            console.log("Something went wrong printing on printNode: ", e);
            showPrintingError();
        },
    });
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function autoLogoutAfterSeconds(timeOut){
    setTimeout(function () {
        window.location.href = Routing.generate('logout');
    }, timeOut*1000);
}