/**
 * App
 *
 * Load the config so we can build our app
 * When the config is loaded we need jQuery to initialize the component loader.
 */
var fast_click = require('fastclick');
require('select2');
require('select2/dist/js/i18n/en');
require('select2/dist/js/i18n/nl');
require('select2/dist/js/i18n/fr');
require('select2/dist/js/i18n/de');

// FastClick polyfill to remove click delays on browsers with touch UIs
fast_click.attach( document.body );

// Bootstrap
require('bootstrap/dist/js/bootstrap.bundle');

// Font Awesome
require('fontawesome/index');

// Main
require('./app/main');

// Component loader
require('./components/select2.js');
require('./components/lightbox.js');
require('./components/datetimepicker.js');
require('./components/websockets.js');
require('./components/instructions.js');
require('./components/questions.js');
require('./components/phonenumberfield.js');
require('./components/qrcode_scanner.js');
require('./components/default_host_for_visit_reason.js');

if ($(".auto-login")[0]) {
    require('./components/auto_login.js');
}
// $( '[data-component]' ).each( function() {
// 	require( './components/' + $( this ).attr( 'data-component' ));
// } );
