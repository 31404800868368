import { disableInactivityTimeout, enableInactivityTimeout } from './inactivity_timeout.js';
import { initBadgePrintingOnCheckIn } from '../app/main.js';
import { autoLogoutAfterSeconds} from "../app/main.js";

var websocket;

(function initWebsocketConnection() {
    websocket = WS.connect(_WS_URI);

    websocket.on("socket/connect", function (session) {
        //session is an Autobahn JS WAMP session.
        console.log("Successfully connected to Websocket server!");

        if ($('.voiceKing_call_step').length) {
            // visitor reaches the call step
            initCallClient('call', session);
        }

        if ($('.stratos_call_step').length) {
            // visitor reaches the call step
            initCallClient('stratos_call', session);
        }

        if ($(".check_in_badge").length && $(".printing").length) {
            // Visistor reaches print badge step
            if ($('.printing').data('unique-identifier-flow') === 'ExternalBadgeCode') {
                // We're in the external_badge_code flow context
                showPrintingStatusArea('pending');
                subscribeWaitingForExternalBadgeCodeTopic(session);
            } else {
                // Skip the whole websocket thing, we're not waiting for an externalBadgeCode.
                // We already have a the default unique ID to use here.
                showPrintingStatusArea('started');
                initBadgePrintingOnCheckIn();
                initGHConnectorOnCheckIn(session);
            }
        }

        if ($(".confirm.completed").length && $(".confirm.completed").length) {
            if ($('.confirm.completed').data('speed-check') === 1) {
                autoLogoutAfterSeconds(15);
            }
        }

        if ($(".check_out_confirmed").length && $(".confirm").length){
            initGHConnectorOnCheckOut(session);
        }

        subscribeRegistrationProcessTopic(session);
    });

    websocket.on("socket/disconnect", function (error) {
        console.log("Disconnected for " + error.reason + " with code " + error.code);

        displayCallStatus(STATE_ERROR, "Websocket server not available");
    });
})();

function initGHConnectorOnCheckIn(session) {
    let entryPointId = $('.printing').data('entry-point-id');
    let registrationId = $('.printing').data('registration-id');
    if (registrationId !== undefined) {
        initGHConnectorClient(session, entryPointId, registrationId,"check_in_frontend");
    }
}

function initGHConnectorOnCheckOut(session) {
    let entryPointId = $('.confirm').data('entry-point-id');
    let registrationId = $('.confirm').data('registration-id');
    if (registrationId !== undefined) {
        initGHConnectorClient(session, entryPointId, registrationId,"check_out_frontend");
    }
}

async function initGHConnectorClient(session, entryPointId, registrationId, action){
    console.log("[GHC] Start connector");

    // subscribe to a topic of "connector_command"
    const topic = "connector_command/" + entryPointId + "/" + action + "/" + registrationId;
    session.subscribe(topic, function (uri, payload) {
        console.log("[GHC] Received connection command", payload);

        if (!payload.hasOwnProperty('connector_command')) {
            console.log("[GHC] Invalid connector command data received");
            return;
        }

        if (
            !payload.connector_command.hasOwnProperty('state')
            || !payload.connector_command.hasOwnProperty('message')
        ) {
            console.log("[GHC] Invalid connector command data received");
            return;
        }

        if (payload.connector_command.state === 'available'
            && payload.connector_command.hasOwnProperty('data')) {
            console.log('[GHC] Connector is available');

            if (payload.connector_command.data.hasOwnProperty('uri')
                && payload.connector_command.data.hasOwnProperty('payload')) {
                sendToGHConnector(session, payload.connector_command.data.uri, payload.connector_command.data.payload, topic);
            }else{
                console.log('[GHC] No uri or payload, nothing to do');
            }
        } else {
            console.log('[GHC] No connector is available');
        }
    });
}

function sendToGHConnector(session, uri, payload, topic){
    const websocket = new WebSocket(uri);
    websocket.onmessage = function(event) {
        console.log(`[GHC] Data received from server: ${event.data}`);
        console.log(`[GHC] Sent to ${topic}: ${event.data}`);
        session.publish(topic, event.data);

        websocket.close();
    };

    websocket.onopen = function(e) {
        console.log("[GHC] Connection established");
        console.log("[GHC] Sending to server " + payload);
        websocket.send(payload);
    };

    websocket.onclose = function(event) {
        if (event.wasClean) {
            console.log(`[GHC] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log('[GHC] Connection died');
        }
    };

    websocket.onerror = function(error) {
        console.log('[GHC] Connection called onerror');
    };
}

const STATE_ERROR = 'error';
const STATE_DIALING = 'dialing';
const STATE_IN_USE = 'in_use';
const STATE_UNAVAILABLE = 'unavailable';
const STATE_DONE = 'done';
const STATE_CONFIRMATION_REQUIRED = 'confirmation_required';
const STATE_ACCESS_GRANTED = 'access_granted';
const STATE_ACCESS_DENIED = 'access_denied';

function initCallClient(prefix, session) {
    disableInactivityTimeout();
    let registrationId = $('input[name=registration_id]').val();
    let visitorConfirmationRequired = $('input[name=visitor_confirmation]').val();

    if (!registrationId) {
        displayCallStatus(STATE_ERROR, "No registration ID found.");
        return;
    }

    if (!visitorConfirmationRequired) {
        displayCallStatus(STATE_ERROR, "No visitor confirmation flag found.");
        return;
    }

    /**
     * Subscribe to a topic = keep listening for messages
     * - call_id = is the registrationId
     *
     * Here we define the topic name we are listening to, f.e.:
     * - "voice_king_call/{call_id}/{visitor_confirmation_required}"
     * - "stratos_call/{call_id}/{visitor_confirmation_required}"
     */
    session.subscribe(prefix + '/' + registrationId + '/'+ visitorConfirmationRequired, function (uri, payload) {
        console.log("Received call status message", payload);

        if (!payload.hasOwnProperty('call_status')) {
            displayCallStatus(STATE_ERROR, "Invalid status data received");
            return;
        }

        if (!payload.call_status.hasOwnProperty('state') || !payload.call_status.hasOwnProperty('message')) {
            displayCallStatus(STATE_ERROR, "Invalid status data received");
            return;
        }

        displayCallStatus(payload.call_status.state, payload.call_status.message);
    });
}

function displayCallStatus(state, message) {
    let callStatusIndicator = $(".call_status_indicator");
    if (!callStatusIndicator.length) {
        return;
    }
    let nextStepBtn = $(".btn-next");
    let visitorConfirmationRequired = $('input[name=visitor_confirmation]').val();

    callStatusIndicator.removeClass('dialing');
    callStatusIndicator.removeClass('calling');
    callStatusIndicator.removeClass('error');
    callStatusIndicator.removeClass('access_granted');
    callStatusIndicator.removeClass('access_denied');

    switch (state) {
        case STATE_ERROR:
            callStatusIndicator.addClass('error');
            enableInactivityTimeout();
            break;
        case STATE_DIALING:
            callStatusIndicator.addClass('dialing');
            message = Translator.trans("call.state_dialing", {}, "messages");
            disableInactivityTimeout();
            break;
        case STATE_IN_USE:
            callStatusIndicator.addClass('calling');
            message = Translator.trans("call.state_in_use", {}, "messages");
            disableInactivityTimeout();
            break;
        case STATE_UNAVAILABLE:
            callStatusIndicator.addClass('error');
            message = Translator.trans("call.state_unavailable", {}, "messages");
            enableInactivityTimeout();
            break;
        case STATE_DONE:
            setTimeout(function() {
                callStatusIndicator.addClass('access_granted');
            }, 100); // reset the css3 animation
            message = Translator.trans("call.state_done", {}, "messages");

            if (nextStepBtn.length) {
                nextStepBtn.removeClass('d-none');

                // automatically move to the next step after 2 seconds
                setTimeout(function () {
                    window.location.href = Routing.generate('next_step');
                }, 2000);
            } else {
                enableInactivityTimeout();
            }

            break;
        case STATE_CONFIRMATION_REQUIRED:
            callStatusIndicator.addClass('dialing');
            message = Translator.trans("call.state_confirmation_required", {}, "messages");
            enableInactivityTimeout(); // In case no confirmation is given, we don't want the user to wait endlessly
            break;
        case STATE_ACCESS_GRANTED:
            setTimeout(function() {
                callStatusIndicator.addClass('access_granted');
            }, 100); // reset the css3 animation
            message = Translator.trans("call.state_access_granted", {}, "messages");

            if (nextStepBtn.length) {
                nextStepBtn.removeClass('d-none');

                // automatically move to the next step after 2 seconds
                setTimeout(function () {
                    window.location.href = Routing.generate('next_step');
                }, 2000);
            } else {
                enableInactivityTimeout();
            }

            break;
        case STATE_ACCESS_DENIED:
            setTimeout(function () {
                callStatusIndicator.addClass('access_denied');
            }, 100); // reset the css3 animation
            message = Translator.trans("call.state_access_denied", {}, "messages");
            enableInactivityTimeout();
            break;
    }

    let callStatusMessage = $(".call_status");
    if (callStatusMessage.length) {
        if (STATE_ERROR === state) {
            message = "Error: " + message + "\n" + Translator.trans("call.error_notify_employee", {}, "messages");
        }
        callStatusMessage.text(message);
        callStatusMessage.html(callStatusMessage.html().replace(/\n/g,'<br/>'));
    }

    if (nextStepBtn.length && STATE_ACCESS_GRANTED !== state) {
        nextStepBtn.addClass('d-none');
    }
}

function subscribeRegistrationProcessTopic(session) {
    let processId = $('input[name=process_id]').val();

    if (!processId) {
        console.log("No process ID found.");
        return;
    }

    // subscribe to a topic "registration_process/{process_id}"
    session.subscribe("registration_process/" + processId, function (uri, payload) {
        console.log("Received registration process message", payload);

        if (!payload.hasOwnProperty('registration_process')) {
            console.log("Invalid registration process data received");
            return;
        }

        if (!payload.registration_process.hasOwnProperty('state') || !payload.registration_process.hasOwnProperty('message')) {
            console.log("Invalid registration process data received");
            return;
        }

        handleRegistrationProcessState(payload.registration_process.state);
    });
}

function subscribeWaitingForExternalBadgeCodeTopic(session) {
    let registrationId = $('.printing').data('registration-id');

    if (!registrationId) {
        console.log("No registration ID found.");
        return;
    }

    // subscribe to a topic "registration_process/{process_id}"
    session.subscribe("waiting_for_external_badge_code/" + registrationId, function (uri, payload) {
        console.log("Received external badge code message", payload);

        showPrintingStatusArea('started');

        if (!payload.hasOwnProperty('waiting_for_external_badge_code')) {
            console.log("Invalid external badge code data received");
            return;
        }

        if (
            !payload.waiting_for_external_badge_code.hasOwnProperty('state')
            || !payload.waiting_for_external_badge_code.hasOwnProperty('message')
        ) {
            console.log("Invalid external badge code data received");
            return;
        }

        if (payload.waiting_for_external_badge_code.state === 'available') {
            console.log('An external badge code is available');
            initBadgePrintingOnCheckIn();
            initGHConnectorOnCheckIn(session);
        } else {
            console.log('No external badge code is available');
            showPrintingStatusArea('failed');
        }
    });
}

function showPrintingStatusArea(printStatus) {
    const $printPending = $('#printPending');
    const $printStarted = $('#printStarted');
    const $printFailed = $('#printFailed');

    if (! $printPending.hasClass('d-none')) {
        $printPending.addClass('d-none');
    }

    if (! $printStarted.hasClass('d-none')) {
        $printStarted.addClass('d-none');
    }

    if (! $printFailed.hasClass('d-none')) {
        $printFailed.addClass('d-none');
    }

    if (printStatus === 'pending') {
        if ($printPending.hasClass('d-none')) {
            $printPending.removeClass('d-none');
        }
    } else if (printStatus === 'started') {
        if ($printStarted.hasClass('d-none')) {
            $printStarted.removeClass('d-none');
        }
    } else if (printStatus === 'failed') {
        if ($printFailed.hasClass('d-none')) {
            $printFailed.removeClass('d-none');
        }
    }
}

let previousRegistrationProcessState = "normal";

function handleRegistrationProcessState(state) {

    let registrationsTemporarilyDisallowedOverlay = $(".registrations_temporarily_disallowed_overlay");
    if (!registrationsTemporarilyDisallowedOverlay.length) {
        return;
    }

    let wrapperDivs = $(".wrapper>div, .wrapper>form"); // everything but the header

    if (previousRegistrationProcessState === state) {
        return; // do nothing
    }

    previousRegistrationProcessState = state;

    if ("normal" === state) {
        window.location.href = Routing.generate('check_in_cancel'); // restart checkin procedure
    }
    else if ("registrations_temporarily_disallowed" === state) {
        wrapperDivs.addClass('blurred_overlay');
        registrationsTemporarilyDisallowedOverlay.removeClass('d-none');
    }
}
