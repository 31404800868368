require('select2/dist/css/select2.css');

$(function () {
    var $select = $('.select');

    if ($select.length > 0) {
        $select.each(function () {
            $(this).select2({
                theme: "gatehouse"
            });
        });

        //bugfix for IOS safari double click
        $('.select2-container')
            .off('touchstart')
            .on('touchstart', function (e) {
                e.stopPropagation();
            })
            .siblings('select')
            .off('select2:open')
            .on('select2:open', function () {
                $('.select2-results__options')
                    .off('touchstart')
                    .on('touchstart', 'li', function (e) {
                        e.stopPropagation();
                    });
            });
    }
});
