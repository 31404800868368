$(document).ready(function() {
    let $questions = $('#questions-form');
    if ($questions.length === 0) {
        return;
    }

    $questions.data('valid', false);

    $questions.on('change', 'input[type="radio"]', function (event){
        let $currentTarget = $(event.currentTarget);
        let $parent = $currentTarget.parents('.question-parent');
        let isCorrect = String($currentTarget.val()) == String($parent.data('answer'));
        $parent.attr('data-is-correct', isCorrect ? '1': '0');

        let correctHtml = '<p class="info color-success">' + $parent.data('correct') + '</p>';
        let errorHtml = '<p class="info color-error">' + $parent.data('error') + '</p>';
        let $feedbackElement = $parent.find('.question-feedback');
        if ($feedbackElement.length === 0) {
            $parent.append('<div class="question-feedback"></div>');
            $feedbackElement = $parent.find('.question-feedback');
        }
        $feedbackElement.html(isCorrect ? correctHtml : errorHtml);

        toggleNextButtonClickability();
    });

    toggleNextButtonClickability();
});

function toggleNextButtonClickability()
{
    let $button = $('.btn#next');
    let allCorrect = true;

    if ($button.length === 0) {
        console.log('There must be a next button on the Q&A page.');
    }

    if ($('[data-is-correct="1"]').length === 0) {
        allCorrect = false;
    }

    $button.toggleClass('disabled', !allCorrect);
}
