require('jQuery-Scanner-Detection');
import DeviceDetector from "device-detector-js";
const jsQR = require("jsqr");

var $qrFastCheckoutInput = $("#register__qrinput-fastcheckout");
if ($qrFastCheckoutInput.length) {
    $qrFastCheckoutInput.val('').focus(); // initially set the focus (should already be good thanks to html autofocus)

    document.addEventListener('keydown', function(e) {
        if((e.target.id !== 'badge_number' || e.target.id !== 'mobile') && !IS_SPEED_CHECK){
            // in case the focus changed to another element, check if we should set it back to this input
            let shouldSetFocus = $.magnificPopup.instance.isOpen !== true;
            if (shouldSetFocus === true) {
                $qrFastCheckoutInput.focus();
            }
        }
    });
}

$("#register__qractivate").click(function(event) {
    event.preventDefault();
    if (useQrScannerAsDefault()) {
        // QR code scan via camera
        openCameraQRCodeScanner();
    } else {
        // QR code scan via external keyboard scanner
        $("#register__qrinput").val('').focus();
    }
});

var $qrInput = $("#register__qrinput");
$qrInput
    .focusin(allowScanning)
    .focusout(disallowScanning)
    .scannerDetection()
    .val('');

//external scanner: make sure the right input gets the focus (differnt pages use different input elements)
if (!$("#register__qrinput-fastcheckout").length && (!$("#badge_number").length || useQrScannerAsDefault())) {
    $qrInput.focus();
}

$qrInput
    .bind("scannerDetectionError", enterVcard)
    .bind("scannerDetectionComplete", enterVcard)
;

function useQrScannerAsDefault() {
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);

    // for some reason Apple devices don't match as a tablet/phone device, so we use the OS name.
    if (device.device.type === "smartphone" || device.device.type === "tablet" || device.os.name === "iOS") {
        // QR code scan via camera (#register__qrinput)
        return true;
    }

    //user probaby will use external usb scanner (#badge_number)
    return false;
}

function allowScanning() {
    $("#register__qrlabel").html(Translator.trans("register.scanning_enabled", {}, "messages"));
}

function disallowScanning() {
    $("#register__qrlabel").html(Translator.trans("register.use_qr_code", {}, "messages"));
}

function enterVcard() {
    var $qrInput = $("#register__qrinput");
    var vcard = $qrInput.val();
    if (!vcard) {
        return;
    }

    $qrInput.val('');

    sendVcard(vcard);
}

function checkoutUser(code) {
    let $input = $('[name="badge_number"]')
    $input.val(code);
    $("#checkoutForm").submit();
}

function sendVcard(vcard) {
    $.ajax({
        url: Routing.generate('ajax_vcard_preregistration', {license: $('select[name$="[license]"] option:selected').val()}, true),
        type: 'POST',
        dataType: 'json',
        data: {
            vcard: vcard
        },
        success: function (data) {
            if (!data) {
                return;
            }

            for (var key in data) {
                var value = data[key];

                if (value === null) {
                    continue;
                }

                var $input = $('[name="registration[' + key + ']"]');

                if ($input.is('select')) {
                    $input.select2('val', [value]);
                }
                else if ($input.is('[type="checkbox"]')) {
                    $input.prop("checked", value);
                }
                else {
                    $input.val(value);
                }
            }
        },
        error: function (xhr) {
            $("#register__qrlabel").html(xhr.responseJSON);
        }
    });
}

var qrScannerOverlay = $('#qrscanner_overlay');
if (qrScannerOverlay.length) {
    qrScannerOverlay.click(function () {
        $(this).removeClass('d-flex');
        $(this).addClass('d-none');
    });
}

var $nextQrCode = $('#registration_nextQrCode');
if ($nextQrCode) {
    if (typeof IS_SPEED_CHECK !== "undefined") {
        if(IS_SPEED_CHECK === true){
            $nextQrCode.hide();
        }else{
            $nextQrCode.html($('<i class="fa fa-fast-forward" aria-hidden="true"></i> <i class="fa fa-qrcode" aria-hidden="true"></i>'))
        }
    }else{
        $nextQrCode.html($('<i class="fa fa-fast-forward" aria-hidden="true"></i> <i class="fa fa-qrcode" aria-hidden="true"></i>'))
    }
}

if (1 === qrScannerOverlay.data('auto-activate')) {
    openCameraQRCodeScanner();
}

function openCameraQRCodeScanner() {
    var video = document.createElement("video");
    var canvasElement = document.getElementById("qrscanner_overlay__canvas");
    var canvas = canvasElement.getContext("2d");
    var loadingMessage = document.getElementById("qrscanner_overlay__loading_message");
    var outputMessage = document.getElementById("qrscanner_overlay__output_message");
    var codeWasFound = false;

    qrScannerOverlay.addClass('d-flex');
    qrScannerOverlay.removeClass('d-none');
    outputMessage.innerText = Translator.trans("register.scan_a_qr_code", {}, "messages");

    function drawLine(begin, end, color) {
        canvas.beginPath();
        canvas.moveTo(begin.x, begin.y);
        canvas.lineTo(end.x, end.y);
        canvas.lineWidth = 4;
        canvas.strokeStyle = color;
        canvas.stroke();
    }

    let useFrontCamera = canvasElement.getAttribute('data-front-facing');
    let facingMode = useFrontCamera ? "user" : "environment";
    /**
     * global.navigator.mediaDevices: This only works over HTTPS!
     * Use facingMode: environment to attemt to get the front camera on phones
     */
    global.navigator.mediaDevices.getUserMedia({ video: { facingMode: facingMode } }).then(function(stream) {
        video.srcObject = stream;
        video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        video.play();
        loadingMessage.innerText = "⌛ Loading video...";
        requestAnimationFrame(cameraFrameTick);
    });

    function cameraFrameTick() {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
            loadingMessage.hidden = true;
            canvasElement.hidden = false;

            canvasElement.height = video.videoHeight;
            canvasElement.width = video.videoWidth;
            canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
            var code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
            });

            if (code) {
                drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
                drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
                drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
                drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

                if (false === codeWasFound) {
                    showScannedCodeAndCloseModal(code);
                }
                codeWasFound = true;
            }
        }

        requestAnimationFrame(cameraFrameTick);
    }

    function showScannedCodeAndCloseModal(code) {
        outputMessage.innerText = Translator.trans("register.scanned_qr_code", {}, "messages") + code.data;

        if(!canvasElement.getAttribute("data-is-checkout-scan")) {
            sendVcard(code.data);
        } else {
            checkoutUser(code.data);
        }

        setTimeout(function() {
            video.pause();
            video.remove();
            qrScannerOverlay.addClass('d-none');
            qrScannerOverlay.removeClass('d-flex');
            loadingMessage.hidden = false;
            canvasElement.hidden = true;
        }, 1000);
    }
}
