
/**
 * Auto-login
 *
 * Autologin file, must only be used at login page
 *
 */

import {
    getCookie
} from "../app/main.js";

$( function() {
    checkAutoLogin(10);
});

function showAutoLoginMessage(waitSec) {

    $('.auto-login span').html(waitSec);
    startAutoLoginSpanTimer();

    $('.auto-login').removeClass('d-none');
    $('.auto-login button').on('click', function(){
        if(autoLoginTimeout !== undefined){
            clearTimeout(autoLoginTimeout);
        }
        $('.auto-login').addClass('d-none');
        $("#username").focus();
    });
}

function startAutoLoginSpanTimer(){
    var intervalId = window.setInterval(function(){
        var timer = parseInt($('.auto-login span').html());
        timer -= 1;

        $('.auto-login span').html(timer);
        if(timer <= 0){
            clearInterval(intervalId)
        }
    }, 1000);
}

var autoLoginTimeout;
function checkAutoLogin(waitSec){
    waitSec = (waitSec === undefined) ? 0 : waitSec;
    var gal = getCookie("gal"); //from main.js
    if(gal){
        var base64 = atob(gal);
        if(base64){
            showAutoLoginMessage(waitSec);
            autoLoginTimeout = setTimeout(
                function() {
                    window.location.href = base64;
                },
                1000*waitSec);
        }
    }
}
