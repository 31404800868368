import * as intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';
import {
    getCookie,
    setCookie
} from "../app/main.js";

$(document).ready(function() {
    var phoneNumberFields = document.querySelectorAll(".phone-number-selector");

    if (!phoneNumberFields) {
        return;
    }

    for (var i = 0; i < phoneNumberFields.length; ++i) {
        var phoneNumberField = phoneNumberFields[i];
        var initialCountry = $(phoneNumberField).data('initial-country');
        if ('' === initialCountry) {
            initialCountry = 'auto';
        }

        var instance = intlTelInput(phoneNumberField, {
            initialCountry: initialCountry,
            geoIpLookup: function(success, failure) {

                var initCountry = getCookie("tel-cntry");
                if(initCountry === undefined || initCountry === ""){
                    setCookie("tel-cntry", "BE", 365); //set default

                    $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                        var countryCode = (resp && resp.country) ? resp.country : "";
                        setCookie("tel-cntry", countryCode, 365);
                        success(countryCode);
                    });
                }else{
                    success(initCountry)
                }

            },
            nationalMode: false,
            utilsScript: "intl-tel-input/build/js/utils.js",
        });

        phoneNumberField.addEventListener('focusout', function(event) {
            instance.setNumber(instance.getNumber());
        });
    }
});
