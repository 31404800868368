/**
 * Instructions (Pop-up with PDFs and Videos)
 *
 * This component requires jQuery and Magnific popup to function.
 * You can require it on the page using <div data-component="video"></div>
 */

require('magnific-popup');
import Player from '@vimeo/player';
var items = null;

$(document).ready(function() {
    let $instructionsForm = $("#instructions_form");
    let $instructions = $('.instruction');

    if (0 === $instructionsForm.length) {
        return;
    }

    if (0 === $instructions.length) {
        return;
    }

    let $isReadingInstructionsMandatoryFlag = $('input[name=is_reading_instructions_mandatory]');
    let isReadingInstructionsMandatory = $isReadingInstructionsMandatoryFlag.val();
    let $instructionCheckboxes = $instructionsForm.find('input[name^="instruction["][data-toggle="tooltip"]');

    // On every checkbox click, toggle if we should see a "success completed box"
    $('input[type="checkbox"]').on('change', function(e){
        let isCheckedByUser = (e.originalEvent && e.originalEvent.isTrusted);
        toggleInstructionsCompletedAlert(this, !isCheckedByUser);
    });

    // Enable tooltips on hover
    $instructionCheckboxes.tooltip();

    if (0 === $('.js-embed').length) {
        return;
    }

    let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
    let controls = ((isMobile) ? "1" : "0");

    let markupOfButton = '<button title="%title%" type="button" class="btn btn-primary btn-instruction %dir%-instruction" style="color: white;">' + Translator.trans('label.approve_instruction', {}, 'messages') + '</button>';
    let config = {
        gallery: {
            enabled: true, // set to true to enable gallery
            preload: [0, 2], // read about this option in next Lazy-loading section
            navigateByImgClick: true,
            arrowMarkup: markupOfButton,
            tPrev: '', // title for left button
            tNext: '', // title for right button
            tCounter: '%curr%/%total%: <span id="instruction-title"></span>' // markup of counter
        },
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        iframe: {
            // markup is required to hook onto events
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<div class="mfp-title"></div>'+
                '<div class="mfp-counter"></div>' +
                '<iframe id="instruction_player" class="mfp-iframe" frameborder="0" allowfullscreen allow="autoplay; fullscreen"></iframe>' +
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
            patterns: {
                youtu: {
                    index: 'youtu.be',
                    id: function (url) {
                        // Capture everything after the hostname, excluding possible querystrings.
                        var m = url.match(/^.+youtu.be\/([^?]+)/);

                        if (null !== m) {
                            return m[1];
                        }

                        return null;
                    },
                    // Use the captured video ID in an embed URL.
                    src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0&modestbranding=1&controls=' + controls
                },
                youtube: {
                    index: 'youtube.com',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0&modestbranding=1&controls=' + controls
                },
                vimeo: {
                    index: 'vimeo.com',
                    id: function (url) {
                        // Capture everything after the hostname, excluding possible querystrings.
                        var m = url.match(/^.+vimeo.com\/([^?]+)/);

                        if (null !== m) {
                            return m[1];
                        }

                        return null;
                    },
                    //enable controls for mobile, bugfix!
                    src: 'https://player.vimeo.com/video/%id%?autoplay=1&controls=' + controls
                },
            }
        },
        callbacks: {
            // Open is only executed once when opening (if closed, then you will get here again)
            // Open is executed after "change" callback
            open: function () {
                // Disable clicking the background, so you can not close popup, unless using "close"-button
                this.st.closeOnBgClick = false;

                items = $.magnificPopup.instance.items;

                // We have only one item, so no gallery will be shown, but want the button
                if (1 === items.length) {
                    $(markupOfButton).insertAfter($('#instruction_player').parent().parent());
                }

                // On open (happens only once) we must disable button if we are in vimeo situation
                let iframe = $(this.content).find('iframe');
                let isVimeoVideo = isAVimeoVideo(iframe.attr('src'));
                if (isVimeoVideo) {
                    $('.btn-instruction').attr('disabled', true);
                }

                $('.btn-instruction').on('click', function () {
                    let isLatest = false;
                    let previousIndex = 0;
                    let maximumIndex = $.magnificPopup.instance.items.length - 1;

                    // New index = 0, we come from latest index
                    if (0 === $.magnificPopup.instance.index) {
                        previousIndex = maximumIndex;
                        isLatest = true;
                    } else if (0 < $.magnificPopup.instance.index) {
                        previousIndex = $.magnificPopup.instance.index - 1;
                    }

                    // This event has been sent later than the callback->change, so we need "-1" to get previous
                    markCheckboxAsChecked(previousIndex);

                    // Close popup, if latest
                    if (isLatest) {
                        $.magnificPopup.close();
                    }
                });

                // toggle close button
                $.magnificPopup.instance.st.showCloseBtn = shouldProhibitClosing();
                $('.mfp-close').toggleClass('d-none', shouldProhibitClosing());
            },
            close: function () {
                $('.btn-instruction').removeAttr('disabled');
            },
            change: function (item) {
                let iframe = $(this.content).find('iframe');
                let isVimeoVideo = isAVimeoVideo(iframe.attr('src'));
                let $activeInstruction = $($('.instruction').get($.magnificPopup.instance.index));

                $(this.content).find('#instruction-title').text($activeInstruction.data('description'));

                if (isVimeoVideo) { // don't do this for PDF's!
                    // auto close the instruction after the video has finished
                    var player = new Player(iframe);
                    $('.btn-instruction').attr('disabled', true);

                    player.on('ended', function () {
                        $('.btn-instruction').removeAttr('disabled');

                        var currentIndex = $.magnificPopup.instance.index;
                        markCheckboxAsChecked(currentIndex);

                        if (currentIndex + 1 === items.length) {
                            $.magnificPopup.close();
                        }
                    });
                }
            },
        }
    };

    $('.js-embed').magnificPopup(config);

    $('#instructions-box').show();

    //iOs double click bugfix (because of hover, you need to double tap otherwise)
    var onTriggerType = (isMobile) ? "touchend" : "click";
    $('#open-instructions').on(onTriggerType, function () {
        $instructions[0].click();
    });

    if ('1' === isReadingInstructionsMandatory) {
        // Open first item automatically
        $instructions[0].click();

        $instructionCheckboxes.on('click', function (event) {
            if (!$(this).attr('instructions-read'))
            {
                event.preventDefault(); // prevent this checkbox from checking. We can't use disabled, since the HTML5 validation would then skip this checkbox
            }
        });
    }
});

function shouldProhibitClosing() {
    let isReadingInstructionsMandatoryFlag = $('input[name=is_reading_instructions_mandatory]');
    let isReadingInstructionsMandatory = isReadingInstructionsMandatoryFlag.val();

    return (isReadingInstructionsMandatory.length && "1" === isReadingInstructionsMandatory);
}

function isAVimeoVideo(url)
{
    var regex = new RegExp( /^.+vimeo.com\/([^?]+)/ );

    return regex.test(url);
}

function markCheckboxAsChecked(index)
{
    var currentElement = items[index].el[0];
    var $parentElement = $(currentElement).closest('.checkbox-label-group');
    var $checkboxElement = $parentElement.find('input[type="checkbox"]');

    $checkboxElement.tooltip('dispose');
    $checkboxElement.attr('instructions-read', 1);
    $checkboxElement.attr('checked', 'checked');
    $checkboxElement.prop('checked', true);
    $checkboxElement.trigger('change');
}

function toggleInstructionsCompletedAlert(elem, goToNextStepOnFinished)
{
    if ($(elem).is(':checked')) {
        let instructionId = $(elem).data('instruction-id');
        var targetUrl = Routing.generate('ajax_add_accepted_instruction', {'instruction-id': instructionId});
        getRequest(targetUrl);
    }

    let numberOfCheckboxes = $('input[type="checkbox"]').length;
    let $checkedCheckboxes = $('input[type="checkbox"]:checked');

    let completed = numberOfCheckboxes === $checkedCheckboxes.length;
    $('#instructions-completed').toggle(completed);

    if(completed && goToNextStepOnFinished){
        //Go to next step
        window.location.href = Routing.generate('next_step');
    }
}

function getRequest(targetUrl)
{
    var response = [];
    $.ajax({
        url: targetUrl,
        type: 'GET',
        cache: false,
        dataType: 'json',
        //data: postData,
        async: false
    }).done(function (data) {
        response = data;
    });

    return response;
}

