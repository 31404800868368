global.moment = require('moment');
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.min');

$( function() {
    $('.date-time-picker').each(function() {
        var defaultDate = $(this).val();

        $(this).attr('data-target', '#' + $(this).attr('id'));

        $(this).datetimepicker({
            format: 'YYYY-MM-DD HH:mm',
            defaultValue: defaultDate,
            viewMode: 'times',
            widgetPositioning: {
                horizontal: 'auto',
                vertical: 'top'
            }
        });

        // fix: initial value disappears after initializing datetimepicker
        $(this).val(defaultDate);
    });
    $('.date-picker').each(function() {
        var defaultDate = $(this).val();

        $(this).attr('data-target', '#' + $(this).attr('id'));

        $(this).datetimepicker({
            format: 'YYYY-MM-DD',
            defaultValue: defaultDate,
            viewMode: 'times',
            minDate: new Date(), // new Date() = today => dont allow picking a date before today
            widgetPositioning: {
                horizontal: 'auto',
                vertical: 'top'
            }
        });

        // fix: initial value disappears after initializing datetimepicker
        $(this).val(defaultDate);
    });
    $('.time-picker').each(function() {
        var defaultDate = $(this).val();

        $(this).attr('data-target', '#' + $(this).attr('id'));

        $(this).datetimepicker({
            format: 'HH:mm',
            defaultValue: defaultDate,
            viewMode: 'times',
            widgetPositioning: {
                horizontal: 'auto',
                vertical: 'top'
            }
        });

        // fix: initial value disappears after initializing datetimepicker
        $(this).val(defaultDate);
    });
} );
