let inactivityTimeoutDisabled = false;
let inactivityTimeout;

// As soon as the user does something, activate this inactivity timer. Otherwise we get stuck in an infinite refresh loop
(function () {
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeypress = resetTimer;
    document.addEventListener('scroll', resetTimer, true);
    resetTimer(); // call this once to set the timer initially

    $(".btn-about").on("click", function(){
        enableInactivityTimeout();
    });

})();

function goToCancelPage() {
    //close popup-about if opened
    if($(".popup-modal-dismiss") !== undefined) {
        $(".popup-modal-dismiss").click();
    }

    if (inactivityTimeoutDisabled === true) {
        console.log("Inactivity timeout reached, but it has been disabled at this time");
        return;
    }

    if (true == IS_CANCEL_STEP) {
        console.log("Inactivity timeout reached, but this is the cancel step already");
        return;
    }

    if ("dev" == APP_ENV) {
        console.log("Inactivity timeout reached, but disabled in development mode");
        return;
    }

    window.location.href = Routing.generate('check_in_cancel'); // restart checkin procedure
}

function resetTimer() {
    if(inactivityTimeout !== undefined){
        clearTimeout(inactivityTimeout);
    }
    inactivityTimeout = setTimeout(goToCancelPage, 120*1000); // 2 minutes
}

export function disableInactivityTimeout() {
    console.log("Inactivity timeout temporarily disabled");
    inactivityTimeoutDisabled = true;
    clearTimeout(inactivityTimeout);
    $(".btn-previous, .btn-cancel, .btn-about").addClass('d-none');
}

export function enableInactivityTimeout() {
    console.log("Inactivity timeout enabled again");
    inactivityTimeoutDisabled = false;
    resetTimer();
    $(".btn-previous, .btn-cancel, .btn-about").removeClass('d-none');
}
